import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Sidebar from '../components/sidebar'
import Post from '../components/post'

class Posts extends React.Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render(){
        const { data } = this.props
        const blogData = data.allWordpressPost.edges
        const { currentPage, numPages } = this.props.pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        const prevPage = currentPage - 1 === 1 ? '/blog/' : `/blog/${(currentPage - 1).toString()}`
        const nextPage = `/blog/${(currentPage + 1).toString()}`

        const Blog = styled.div`
            grid-column: 6 / span 6;
            grid-row: 1 / 4;
            @media screen and (max-width: 767px){
                grid-column: 1 / span 4;
            }
            @media screen and (min-width: 768px) and (max-width: 1023px){
                grid-column: 2 / span 6;
            }
        `

        const SidebarCol = styled.div`
            grid-column: 2 / span 3;
            @media screen and (max-width: 1023px){
                display: none;
            }
        `

        const NavLink = styled(Link)`
            font-size: 0.6875em;
            color: var(--c-grey);
            text-decoration: none;
            transition: 300ms;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 500;
            &:hover{
                color: var(--c-tertiary);
            }
        `

        const NavLinkWrapper = styled.div`
            display: flex;
            justify-content: space-between;
            margin-top: 4rem;
        `
        return (
            <Layout>
                <SEO 
                    pageTitle="Blog" 
                    pageDesc="Think, write and repeat."
                />
                <div className="container">
                    <SidebarCol><Sidebar headingText="Think, write, repeat" /></SidebarCol>
                    
                    <Blog>
                        {
                            blogData.map((edges, i) => {
                                const blogUrl = "/blog/" + edges.node.slug + "/"
                                return(
                                    <Post date={edges.node.date} title={edges.node.title} url={blogUrl} key={i} />
                                )
                            })
                        }

                        <NavLinkWrapper>
                            {!isFirst && (
                                <NavLink to={prevPage} rel="prev">
                                ← Previous Page
                                </NavLink>
                            )}
                            
                            {!isLast && (
                                <NavLink to={nextPage} rel="next">
                                Next Page →
                                </NavLink>
                            )}
                        </NavLinkWrapper>
                    </Blog>
                </div>
            </Layout>
        )
    }
}

export default Posts

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allWordpressPost(
        limit: $limit
        skip: $skip
    ){
        edges{
            node{
                title,
                date(formatString:"MMMM Do, YYYY"),
                slug
            }
        }
    }
  }
`