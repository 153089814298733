import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const Post = (props) => {
    const Post = styled.div`
        margin-bottom: 1.5rem;
        &:hover{
            a{
                text-shadow: -2px 5px 0 var(--c-secondary);
            }
        }
    `
    const PostDate = styled.h6`
        font-size: 0.75em;
        font-weight: normal;
        color: var(--c-grey);
        margin: 0;
    `
    const PostTitle = styled.h3`
        font-size: 2rem;
        margin: 0.5rem 0 0;
        @media screen and (max-width: 767px){
            font-size: 1.5rem;
        }
        a{
            color:black;
            text-decoration: none;
            transition: 300ms;
        }
    `
    return (
        <Post>
            <PostDate>{props.date}</PostDate>
            <PostTitle>
                <Link to={props.url} dangerouslySetInnerHTML={{ __html: props.title }} />
            </PostTitle>
        </Post>
    )
}

export default Post